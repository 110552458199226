<template>
  <v-container fluid class="mx-10 my-10 text-center" id="org-container" v-if="currentOrg">
    <v-row class="mt-10" align="center" justify="center">
      <v-col cols="6" align="left">
        <v-list-item id="org-list-item">
          <v-avatar rounded size="7rem" id="org-avatar">
            <img
              src="https://robohash.org/7d9ac3c7c548614bf3c49a7500a8a473?set=set4&bgset=bg2&size=800x800"
              id="org-avatar-img"
            />
          </v-avatar>
          <v-list-item-content class="mr-auto mx-10" id="org-name">{{ currentOrg.name }}</v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="mt-10" align="center" justify="center">
      <v-col cols="6">
        <v-tabs id="org-tabs">
          <v-tab id="overview-tab">Overview</v-tab>
          <v-tab :to="'/settigs/orgs/' + currentOrg.handle + '/members/'" id="members-tab">{{ $t('Members') }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-dialog id="modal-prevent-closing" ref="modal" :title="$t('sendInvite')" @ok="handleSubmit(newInvite)">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <v-form role="newInvite" @submit.prevent="handleSubmit(newInvite)" id="new-invite-form">
          <v-text-field
            class="mb-3"
            name="inviteEmail"
            id="invite-email"
            type="email"
            :label="$t('inputEmail')"
            rules="required|email"
            v-model="inviteEmail"
            outlined
            dense
          ></v-text-field>
          <v-select
            :menu-props="{ bottom: true, offsetY: true }"
            v-model="selected"
            id="role"
            :items="options"
            outlined
            dense
          ></v-select>
          <div class="text-center">
            <v-btn block id="send-invite" variant="primary" type="submit" class="my-4">{{ $t('sendInvite') }}</v-btn>
          </div>
        </v-form>
      </ValidationObserver>
      <v-btn block variant="primary" id="cancel" v-on:click="onCancel" type="button" class="my-4">{{
        $t('cancel')
      }}</v-btn>
    </v-dialog>
  </v-container>
</template>

<script>
import makeOrgService from '@/services/api/org';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('user');

export default {
  name: 'Org',
  data() {
    return {
      inviteEmail: '',
      selected: null,
      options: [],
      currentOrg: undefined,
    };
  },
  computed: {
    ...mapGetters(['getOrg']),
  },
  methods: {
    newInvite() {
      const inviteEmail = this.inviteEmail;
      const handle = this.$route.params.handle;
      const role = this.selected;
      const orgService = makeOrgService(this.$api);
      orgService
        .newInvite({ inviteEmail, handle, role })
        .then((response) => {
          if (response.status === 200) {
            this.$swal({
              title: this.$t('invite link sent'),
              icon: 'success',
              showConfirmButton: false,
              position: 'top-center',
              timer: 2000,
              toast: true,
            }).then(() => {
              this.$refs.modal.hide();
            });
          }
        })
        .catch((error) => {
          this.$swal({
            title: error,
            icon: 'error',
            showConfirmButton: false,
            position: 'top-center',
            timer: 2000,
            toast: true,
          });
        });
    },
    onCancel() {
      this.inviteEmail = '';
      this.selected = null;
      this.$refs.modal.hide();
    },
  },
  async created() {
    const handle = this.$route.params.handle;
    const orgService = makeOrgService(this.$api);
    this.currentOrg = this.getOrg(handle);
    if (!this.currentOrg) {
      await this.$swal({
        title: this.$t('orgNotFound'),
        icon: 'error',
        showConfirmButton: false,
        position: 'top-center',
        timer: 2000,
        toast: true,
      });
      this.$router.push('/');
    }

    orgService
      .getRoles(this.currentOrg.uid)
      .then(async (response) => {
        this.options = response.data.roles.map((element) => {
          return {
            value: element.uid,
            text: element.name,
          };
        });
      })
      .catch(async (error) => {
        await this.$swal({
          title: error.response.data.error,
          icon: 'error',
          showConfirmButton: false,
          position: 'top-center',
          timer: 2000,
          toast: true,
        });
        this.$router.push('/');
      });
  },
};
</script>
